import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ListGroupLink } from '../widgets/ListGroupLink'
import { DemoButtonsPage } from './DemoButtonsPage'
import { DemoColorsPage } from './DemoColorsPage'
import { DemoFormsPage } from './DemoFormsPage'
import { DemoListGroupPage } from './DemoListGroupPage'
import { DemoMiscPage } from './DemoMiscPage'
import { DemoModalPage } from './DemoModalPage'
import { DemoMultiSelectPage } from './DemoMultiSelectPage'
import { DemoTablePage } from './DemoTablePage'
import { DemoTzPage } from './DemoTzPage'

const UIDemoBasePage = () => (
	<Card>
		<Card.Header>Choose a demo</Card.Header>

		<ListGroup variant="flush">
			<ListGroupLink to="/ui-demo/buttons">Buttons</ListGroupLink>
			<ListGroupLink to="/ui-demo/colors">Colors</ListGroupLink>
			<ListGroupLink to="/ui-demo/forms">Forms</ListGroupLink>
			<ListGroupLink to="/ui-demo/list-group">List Group</ListGroupLink>
			<ListGroupLink to="/ui-demo/misc">Misc (Badges, Tooltips, Toasts, etc.)</ListGroupLink>
			<ListGroupLink to="/ui-demo/modal">Modal</ListGroupLink>
			<ListGroupLink to="/ui-demo/select">Multi-select</ListGroupLink>
			<ListGroupLink to="/ui-demo/table">Tables</ListGroupLink>
			<ListGroupLink to="/ui-demo/tz">Timezone select</ListGroupLink>
		</ListGroup>
	</Card>
)

export const UIDemoPage = () => (
	<Switch>
		<Route component={DemoButtonsPage} path="/ui-demo/buttons" />
		<Route component={DemoColorsPage} path="/ui-demo/colors" />
		<Route component={DemoFormsPage} path="/ui-demo/forms" />
		<Route component={DemoListGroupPage} path="/ui-demo/list-group" />
		<Route component={DemoMiscPage} path="/ui-demo/misc" />
		<Route component={DemoModalPage} path="/ui-demo/modal" />
		<Route component={DemoMultiSelectPage} path="/ui-demo/select" />
		<Route component={DemoTablePage} path="/ui-demo/table" />
		<Route component={DemoTzPage} path="/ui-demo/tz" />
		<Route component={UIDemoBasePage} path="/ui-demo/choose" />
		<Redirect to="/ui-demo/choose" />
	</Switch>
)
