import { useConstant, useFn } from '@eturi/react'
import { Form as FormikForm, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import * as Yup from 'yup'
import type { FormikSubmit } from '../types'
import type { TzOption } from '../widgets/TimezoneSelect'
import { TimezoneSelect } from '../widgets/TimezoneSelect'

type TzFormData = {
	readonly tz?: TzOption
}

export const DemoTzPage = () => {
	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			tz: Yup.object().required(),
		}),
	)

	const INITIAL_VALUES = useConstant(
		(): TzFormData => ({
			tz: undefined,
		}),
	)

	const handleSubmit: FormikSubmit<TzFormData> = useFn((v, helpers) => {
		console.log(v)
		alert(JSON.stringify(v))
		helpers.resetForm()
	})

	return (
		<Card>
			<Card.Header>Timezone select</Card.Header>

			<Card.Body>
				<Formik
					initialValues={INITIAL_VALUES}
					onSubmit={handleSubmit}
					validationSchema={VALIDATION_SCHEMA}
				>
					{() => (
						<Form as={FormikForm}>
							<Form.Group>
								<Form.Label htmlFor="tz-demo">Select Timezone</Form.Label>
								<TimezoneSelect name="tz" />
							</Form.Group>

							<Form.Group>
								<Button variant="success" type="submit">
									Submit
								</Button>
							</Form.Group>
						</Form>
					)}
				</Formik>
			</Card.Body>
		</Card>
	)
}
