import Card from 'react-bootstrap/Card'
import { Banner } from '../widgets/Banners/Banner'
import { BannerWithBtn } from '../widgets/Banners/BannerWithBtn'
import { TruncatedButtonsList } from '../widgets/TruncatedButtonsList'

export const DemoMiscPage = () => {
	const onBtnClick = () => {
		alert('pushed button')
	}

	const shortMessage = 'This is a short test message for the banner component.'
	const longMessage =
		'This is a long test message for the banner component that should span multiple lines and give a good sense of how a long message will look in a banner.  More text here to get a few more characters.'

	return (
		<>
			<Banner msg={shortMessage} />
			<Banner msg={longMessage} />
			<BannerWithBtn msg={shortMessage} btnMsg={'Submit'} onClick={onBtnClick} />
			<BannerWithBtn msg={longMessage} btnMsg={'Submit'} onClick={onBtnClick} />
			<Card className="mt-5">
				<Card.Header>Overflow Buttons</Card.Header>
				<Card.Body>
					<TruncatedButtonsList
						labelMaxLength={20}
						maxVisible={3}
						buttons={[
							{ id: '1', label: 'Operations' },
							{ id: '2', label: 'Manufacturing' },
							{ id: '3', label: 'Management' },
							{ id: '4', label: 'IT' },
							{ id: '5', label: 'Customer Service' },
							{ id: '6', label: 'Legal' },
						]}
					/>
				</Card.Body>
			</Card>
		</>
	)
}
