import capitalize from 'lodash/capitalize'
import Card from 'react-bootstrap/Card'
import ScssColors from '../styles/color-exports.module.scss'

const parseHex = (color: string, stripOpacity = false) => {
	const sliceOffset = color[0] === '#' ? 1 : 0
	const sliceEnd = stripOpacity ? 6 : 8

	let hex = color.slice(sliceOffset, sliceEnd + sliceOffset)

	if (hex.length === 3) hex = Array.from(hex, (v) => v + v).join('')

	return hex
}

export const DemoColorsPage = () => {
	return (
		<Card>
			<Card.Header>Colors</Card.Header>

			<Card.Body>
				{Object.entries(ScssColors).map(([name, hex]) => (
					<ColorItem key={hex + name} hex={hex} name={name} />
				))}
			</Card.Body>
		</Card>
	)
}

type ColorItemProps = {
	readonly hex: string
	readonly name: string
}

const ColorCircleStyle = {
	border: '2px solid #000',
	borderRadius: '20px',
	height: '40px',
	width: '40px',
}

const ColorItem = ({ hex, name }: ColorItemProps) => (
	<div className="d-flex align-items-center justify-content-between py-3">
		<div className="font-weight-bold">{name.split('_').map(capitalize).join(' ')}</div>

		<div className="d-flex align-items-center">
			<div className="text-monospace">#{parseHex(hex)}</div>
			<div className="ml-3" style={{ backgroundColor: hex, ...ColorCircleStyle }} />
		</div>
	</div>
)
