import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { ListGroupLink } from '../widgets/ListGroupLink'

export const DemoListGroupPage = () => {
	return (
		<>
			<ListGroup className="mb-5">
				<ListGroup.Item>Regular (Default)</ListGroup.Item>
				<ListGroup.Item variant="primary">Regular (Primary)</ListGroup.Item>
				<ListGroup.Item variant="secondary">Regular (Secondary)</ListGroup.Item>
				<ListGroup.Item variant="success">Regular (Success)</ListGroup.Item>
				<ListGroup.Item variant="danger">Regular (Danger)</ListGroup.Item>
				<ListGroup.Item variant="warning">Regular (Warning)</ListGroup.Item>
				<ListGroup.Item variant="info">Regular (Info)</ListGroup.Item>
				<ListGroup.Item variant="light">Regular (Light)</ListGroup.Item>
				<ListGroup.Item variant="dark">Regular (Dark)</ListGroup.Item>
				<ListGroup.Item active>Regular (Active)</ListGroup.Item>
				<ListGroup.Item disabled>Regular (Disabled)</ListGroup.Item>
			</ListGroup>

			<ListGroup className="mb-5">
				<ListGroupLink to="/ui-demo/list-group">Link (Default)</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="primary">
					Link (Primary)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="secondary">
					Link (Secondary)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="success">
					Link (Success)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="danger">
					Link (Danger)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="warning">
					Link (Warning)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="info">
					Link (Info)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="light">
					Link (Light)
				</ListGroupLink>

				<ListGroupLink to="/ui-demo/list-group" variant="dark">
					Link (Dark)
				</ListGroupLink>

				<ListGroupLink active to="/ui-demo/list-group">
					Link (Active)
				</ListGroupLink>

				<ListGroupLink disabled to="/ui-demo/list-group">
					Link (Disabled)
				</ListGroupLink>
			</ListGroup>

			<Card className="mb-5">
				<Card.Header>Flush List Group in Card</Card.Header>

				<ListGroup variant="flush">
					<ListGroup.Item>One</ListGroup.Item>
					<ListGroup.Item>Two</ListGroup.Item>
					<ListGroup.Item>Three</ListGroup.Item>
				</ListGroup>
			</Card>
		</>
	)
}
